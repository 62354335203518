// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "PageHeader-module--cds--assistive-text--d0a5a";
export var cdsHideFeedback = "PageHeader-module--cds--hide-feedback--91be0";
export var cdsLayoutConstraintDensity__defaultCondensed = "PageHeader-module--cds--layout-constraint--density__default-condensed--07222";
export var cdsLayoutConstraintDensity__defaultNormal = "PageHeader-module--cds--layout-constraint--density__default-normal--6834f";
export var cdsLayoutConstraintDensity__maxCondensed = "PageHeader-module--cds--layout-constraint--density__max-condensed--02a8e";
export var cdsLayoutConstraintDensity__maxNormal = "PageHeader-module--cds--layout-constraint--density__max-normal--c597b";
export var cdsLayoutConstraintDensity__minCondensed = "PageHeader-module--cds--layout-constraint--density__min-condensed--75d69";
export var cdsLayoutConstraintDensity__minNormal = "PageHeader-module--cds--layout-constraint--density__min-normal--07b5c";
export var cdsLayoutConstraintSize__default2xl = "PageHeader-module--cds--layout-constraint--size__default-2xl--e7cf4";
export var cdsLayoutConstraintSize__defaultLg = "PageHeader-module--cds--layout-constraint--size__default-lg--32447";
export var cdsLayoutConstraintSize__defaultMd = "PageHeader-module--cds--layout-constraint--size__default-md--5831c";
export var cdsLayoutConstraintSize__defaultSm = "PageHeader-module--cds--layout-constraint--size__default-sm--b94fe";
export var cdsLayoutConstraintSize__defaultXl = "PageHeader-module--cds--layout-constraint--size__default-xl--7c719";
export var cdsLayoutConstraintSize__defaultXs = "PageHeader-module--cds--layout-constraint--size__default-xs--eb8f9";
export var cdsLayoutConstraintSize__max2xl = "PageHeader-module--cds--layout-constraint--size__max-2xl--dead9";
export var cdsLayoutConstraintSize__maxLg = "PageHeader-module--cds--layout-constraint--size__max-lg--00367";
export var cdsLayoutConstraintSize__maxMd = "PageHeader-module--cds--layout-constraint--size__max-md--d1357";
export var cdsLayoutConstraintSize__maxSm = "PageHeader-module--cds--layout-constraint--size__max-sm--d5974";
export var cdsLayoutConstraintSize__maxXl = "PageHeader-module--cds--layout-constraint--size__max-xl--a51ad";
export var cdsLayoutConstraintSize__maxXs = "PageHeader-module--cds--layout-constraint--size__max-xs--378d6";
export var cdsLayoutConstraintSize__min2xl = "PageHeader-module--cds--layout-constraint--size__min-2xl--2c948";
export var cdsLayoutConstraintSize__minLg = "PageHeader-module--cds--layout-constraint--size__min-lg--f47a2";
export var cdsLayoutConstraintSize__minMd = "PageHeader-module--cds--layout-constraint--size__min-md--b7dfd";
export var cdsLayoutConstraintSize__minSm = "PageHeader-module--cds--layout-constraint--size__min-sm--37352";
export var cdsLayoutConstraintSize__minXl = "PageHeader-module--cds--layout-constraint--size__min-xl--7081d";
export var cdsLayoutConstraintSize__minXs = "PageHeader-module--cds--layout-constraint--size__min-xs--2c14e";
export var cdsLayoutDensityCondensed = "PageHeader-module--cds--layout--density-condensed--86a82";
export var cdsLayoutDensityNormal = "PageHeader-module--cds--layout--density-normal--be817";
export var cdsLayoutSize2xl = "PageHeader-module--cds--layout--size-2xl--a3ac6";
export var cdsLayoutSizeLg = "PageHeader-module--cds--layout--size-lg--453f3";
export var cdsLayoutSizeMd = "PageHeader-module--cds--layout--size-md--4c3e0";
export var cdsLayoutSizeSm = "PageHeader-module--cds--layout--size-sm--41710";
export var cdsLayoutSizeXl = "PageHeader-module--cds--layout--size-xl--d21c0";
export var cdsLayoutSizeXs = "PageHeader-module--cds--layout--size-xs--73901";
export var cdsShowFeedback = "PageHeader-module--cds--show-feedback--a1c06";
export var cdsSkeleton = "PageHeader-module--cds--skeleton--2acc7";
export var cdsVisuallyHidden = "PageHeader-module--cds--visually-hidden--ab058";
export var darkMode = "PageHeader-module--dark-mode--128f9";
export var pageHeader = "PageHeader-module--page-header--a2718";
export var text = "PageHeader-module--text--4cd9c";
export var withTabs = "PageHeader-module--with-tabs--8b47b";