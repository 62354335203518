// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "PageTabs-module--cds--assistive-text--bc6fa";
export var cdsHideFeedback = "PageTabs-module--cds--hide-feedback--52215";
export var cdsLayoutConstraintDensity__defaultCondensed = "PageTabs-module--cds--layout-constraint--density__default-condensed--93990";
export var cdsLayoutConstraintDensity__defaultNormal = "PageTabs-module--cds--layout-constraint--density__default-normal--51c86";
export var cdsLayoutConstraintDensity__maxCondensed = "PageTabs-module--cds--layout-constraint--density__max-condensed--77347";
export var cdsLayoutConstraintDensity__maxNormal = "PageTabs-module--cds--layout-constraint--density__max-normal--091ef";
export var cdsLayoutConstraintDensity__minCondensed = "PageTabs-module--cds--layout-constraint--density__min-condensed--1d679";
export var cdsLayoutConstraintDensity__minNormal = "PageTabs-module--cds--layout-constraint--density__min-normal--72cd0";
export var cdsLayoutConstraintSize__default2xl = "PageTabs-module--cds--layout-constraint--size__default-2xl--3fbc0";
export var cdsLayoutConstraintSize__defaultLg = "PageTabs-module--cds--layout-constraint--size__default-lg--8e270";
export var cdsLayoutConstraintSize__defaultMd = "PageTabs-module--cds--layout-constraint--size__default-md--53b4c";
export var cdsLayoutConstraintSize__defaultSm = "PageTabs-module--cds--layout-constraint--size__default-sm--49d5a";
export var cdsLayoutConstraintSize__defaultXl = "PageTabs-module--cds--layout-constraint--size__default-xl--7baed";
export var cdsLayoutConstraintSize__defaultXs = "PageTabs-module--cds--layout-constraint--size__default-xs--5ba7b";
export var cdsLayoutConstraintSize__max2xl = "PageTabs-module--cds--layout-constraint--size__max-2xl--d3dd2";
export var cdsLayoutConstraintSize__maxLg = "PageTabs-module--cds--layout-constraint--size__max-lg--41840";
export var cdsLayoutConstraintSize__maxMd = "PageTabs-module--cds--layout-constraint--size__max-md--73e31";
export var cdsLayoutConstraintSize__maxSm = "PageTabs-module--cds--layout-constraint--size__max-sm--c89ca";
export var cdsLayoutConstraintSize__maxXl = "PageTabs-module--cds--layout-constraint--size__max-xl--0a864";
export var cdsLayoutConstraintSize__maxXs = "PageTabs-module--cds--layout-constraint--size__max-xs--42734";
export var cdsLayoutConstraintSize__min2xl = "PageTabs-module--cds--layout-constraint--size__min-2xl--c5b45";
export var cdsLayoutConstraintSize__minLg = "PageTabs-module--cds--layout-constraint--size__min-lg--770e1";
export var cdsLayoutConstraintSize__minMd = "PageTabs-module--cds--layout-constraint--size__min-md--96f62";
export var cdsLayoutConstraintSize__minSm = "PageTabs-module--cds--layout-constraint--size__min-sm--ac3b3";
export var cdsLayoutConstraintSize__minXl = "PageTabs-module--cds--layout-constraint--size__min-xl--4f07a";
export var cdsLayoutConstraintSize__minXs = "PageTabs-module--cds--layout-constraint--size__min-xs--6d1f0";
export var cdsLayoutDensityCondensed = "PageTabs-module--cds--layout--density-condensed--a4aee";
export var cdsLayoutDensityNormal = "PageTabs-module--cds--layout--density-normal--3d9a7";
export var cdsLayoutSize2xl = "PageTabs-module--cds--layout--size-2xl--09f72";
export var cdsLayoutSizeLg = "PageTabs-module--cds--layout--size-lg--32aa3";
export var cdsLayoutSizeMd = "PageTabs-module--cds--layout--size-md--38182";
export var cdsLayoutSizeSm = "PageTabs-module--cds--layout--size-sm--a5784";
export var cdsLayoutSizeXl = "PageTabs-module--cds--layout--size-xl--20af1";
export var cdsLayoutSizeXs = "PageTabs-module--cds--layout--size-xs--0344b";
export var cdsShowFeedback = "PageTabs-module--cds--show-feedback--244da";
export var cdsSkeleton = "PageTabs-module--cds--skeleton--76c16";
export var cdsVisuallyHidden = "PageTabs-module--cds--visually-hidden--08bac";
export var link = "PageTabs-module--link--e7bd6";
export var list = "PageTabs-module--list--901c7";
export var listItem = "PageTabs-module--list-item--6ac02";
export var selectedItem = "PageTabs-module--selected-item--ff637";
export var tabsContainer = "PageTabs-module--tabs-container--15bf8";