// extracted by mini-css-extract-plugin
export var anchor = "AutolinkHeader-module--anchor--1a951";
export var cdsAssistiveText = "AutolinkHeader-module--cds--assistive-text--4af88";
export var cdsHideFeedback = "AutolinkHeader-module--cds--hide-feedback--0ec26";
export var cdsLayoutConstraintDensity__defaultCondensed = "AutolinkHeader-module--cds--layout-constraint--density__default-condensed--edac2";
export var cdsLayoutConstraintDensity__defaultNormal = "AutolinkHeader-module--cds--layout-constraint--density__default-normal--d4c78";
export var cdsLayoutConstraintDensity__maxCondensed = "AutolinkHeader-module--cds--layout-constraint--density__max-condensed--bc203";
export var cdsLayoutConstraintDensity__maxNormal = "AutolinkHeader-module--cds--layout-constraint--density__max-normal--d09e7";
export var cdsLayoutConstraintDensity__minCondensed = "AutolinkHeader-module--cds--layout-constraint--density__min-condensed--7ebc6";
export var cdsLayoutConstraintDensity__minNormal = "AutolinkHeader-module--cds--layout-constraint--density__min-normal--1202f";
export var cdsLayoutConstraintSize__default2xl = "AutolinkHeader-module--cds--layout-constraint--size__default-2xl--205c3";
export var cdsLayoutConstraintSize__defaultLg = "AutolinkHeader-module--cds--layout-constraint--size__default-lg--65576";
export var cdsLayoutConstraintSize__defaultMd = "AutolinkHeader-module--cds--layout-constraint--size__default-md--c1c4b";
export var cdsLayoutConstraintSize__defaultSm = "AutolinkHeader-module--cds--layout-constraint--size__default-sm--cb72a";
export var cdsLayoutConstraintSize__defaultXl = "AutolinkHeader-module--cds--layout-constraint--size__default-xl--d9a06";
export var cdsLayoutConstraintSize__defaultXs = "AutolinkHeader-module--cds--layout-constraint--size__default-xs--edf6a";
export var cdsLayoutConstraintSize__max2xl = "AutolinkHeader-module--cds--layout-constraint--size__max-2xl--1cb88";
export var cdsLayoutConstraintSize__maxLg = "AutolinkHeader-module--cds--layout-constraint--size__max-lg--ece7c";
export var cdsLayoutConstraintSize__maxMd = "AutolinkHeader-module--cds--layout-constraint--size__max-md--99c3e";
export var cdsLayoutConstraintSize__maxSm = "AutolinkHeader-module--cds--layout-constraint--size__max-sm--97283";
export var cdsLayoutConstraintSize__maxXl = "AutolinkHeader-module--cds--layout-constraint--size__max-xl--1173d";
export var cdsLayoutConstraintSize__maxXs = "AutolinkHeader-module--cds--layout-constraint--size__max-xs--0db96";
export var cdsLayoutConstraintSize__min2xl = "AutolinkHeader-module--cds--layout-constraint--size__min-2xl--83569";
export var cdsLayoutConstraintSize__minLg = "AutolinkHeader-module--cds--layout-constraint--size__min-lg--5a2e2";
export var cdsLayoutConstraintSize__minMd = "AutolinkHeader-module--cds--layout-constraint--size__min-md--d430b";
export var cdsLayoutConstraintSize__minSm = "AutolinkHeader-module--cds--layout-constraint--size__min-sm--a796e";
export var cdsLayoutConstraintSize__minXl = "AutolinkHeader-module--cds--layout-constraint--size__min-xl--4fcb9";
export var cdsLayoutConstraintSize__minXs = "AutolinkHeader-module--cds--layout-constraint--size__min-xs--c5dbc";
export var cdsLayoutDensityCondensed = "AutolinkHeader-module--cds--layout--density-condensed--e23e4";
export var cdsLayoutDensityNormal = "AutolinkHeader-module--cds--layout--density-normal--d8bee";
export var cdsLayoutSize2xl = "AutolinkHeader-module--cds--layout--size-2xl--9fd5b";
export var cdsLayoutSizeLg = "AutolinkHeader-module--cds--layout--size-lg--ff1b3";
export var cdsLayoutSizeMd = "AutolinkHeader-module--cds--layout--size-md--d033d";
export var cdsLayoutSizeSm = "AutolinkHeader-module--cds--layout--size-sm--8126e";
export var cdsLayoutSizeXl = "AutolinkHeader-module--cds--layout--size-xl--73b59";
export var cdsLayoutSizeXs = "AutolinkHeader-module--cds--layout--size-xs--4f6c6";
export var cdsShowFeedback = "AutolinkHeader-module--cds--show-feedback--63170";
export var cdsSkeleton = "AutolinkHeader-module--cds--skeleton--1b41b";
export var cdsVisuallyHidden = "AutolinkHeader-module--cds--visually-hidden--25b8f";
export var header = "AutolinkHeader-module--header--888d5";
export var leftAnchor = "AutolinkHeader-module--left-anchor--07711";
export var rightAnchor = "AutolinkHeader-module--right-anchor--73c2a";