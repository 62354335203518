// extracted by mini-css-extract-plugin
export var caption = "DoDontRow-module--caption--b338c";
export var card = "DoDontRow-module--card--4e39e";
export var cardContent = "DoDontRow-module--card-content--897a2";
export var cdsAssistiveText = "DoDontRow-module--cds--assistive-text--a5113";
export var cdsHideFeedback = "DoDontRow-module--cds--hide-feedback--2d31f";
export var cdsLayoutConstraintDensity__defaultCondensed = "DoDontRow-module--cds--layout-constraint--density__default-condensed--caa9d";
export var cdsLayoutConstraintDensity__defaultNormal = "DoDontRow-module--cds--layout-constraint--density__default-normal--c00d5";
export var cdsLayoutConstraintDensity__maxCondensed = "DoDontRow-module--cds--layout-constraint--density__max-condensed--10bfb";
export var cdsLayoutConstraintDensity__maxNormal = "DoDontRow-module--cds--layout-constraint--density__max-normal--19228";
export var cdsLayoutConstraintDensity__minCondensed = "DoDontRow-module--cds--layout-constraint--density__min-condensed--21ba3";
export var cdsLayoutConstraintDensity__minNormal = "DoDontRow-module--cds--layout-constraint--density__min-normal--99c9a";
export var cdsLayoutConstraintSize__default2xl = "DoDontRow-module--cds--layout-constraint--size__default-2xl--7eaa3";
export var cdsLayoutConstraintSize__defaultLg = "DoDontRow-module--cds--layout-constraint--size__default-lg--8e5f1";
export var cdsLayoutConstraintSize__defaultMd = "DoDontRow-module--cds--layout-constraint--size__default-md--b057a";
export var cdsLayoutConstraintSize__defaultSm = "DoDontRow-module--cds--layout-constraint--size__default-sm--47669";
export var cdsLayoutConstraintSize__defaultXl = "DoDontRow-module--cds--layout-constraint--size__default-xl--395fd";
export var cdsLayoutConstraintSize__defaultXs = "DoDontRow-module--cds--layout-constraint--size__default-xs--98e04";
export var cdsLayoutConstraintSize__max2xl = "DoDontRow-module--cds--layout-constraint--size__max-2xl--36136";
export var cdsLayoutConstraintSize__maxLg = "DoDontRow-module--cds--layout-constraint--size__max-lg--7da48";
export var cdsLayoutConstraintSize__maxMd = "DoDontRow-module--cds--layout-constraint--size__max-md--ecab3";
export var cdsLayoutConstraintSize__maxSm = "DoDontRow-module--cds--layout-constraint--size__max-sm--420f8";
export var cdsLayoutConstraintSize__maxXl = "DoDontRow-module--cds--layout-constraint--size__max-xl--3e582";
export var cdsLayoutConstraintSize__maxXs = "DoDontRow-module--cds--layout-constraint--size__max-xs--5705f";
export var cdsLayoutConstraintSize__min2xl = "DoDontRow-module--cds--layout-constraint--size__min-2xl--44a21";
export var cdsLayoutConstraintSize__minLg = "DoDontRow-module--cds--layout-constraint--size__min-lg--cea69";
export var cdsLayoutConstraintSize__minMd = "DoDontRow-module--cds--layout-constraint--size__min-md--28ca0";
export var cdsLayoutConstraintSize__minSm = "DoDontRow-module--cds--layout-constraint--size__min-sm--f9877";
export var cdsLayoutConstraintSize__minXl = "DoDontRow-module--cds--layout-constraint--size__min-xl--b270b";
export var cdsLayoutConstraintSize__minXs = "DoDontRow-module--cds--layout-constraint--size__min-xs--7c10d";
export var cdsLayoutDensityCondensed = "DoDontRow-module--cds--layout--density-condensed--ecf5b";
export var cdsLayoutDensityNormal = "DoDontRow-module--cds--layout--density-normal--65ea3";
export var cdsLayoutSize2xl = "DoDontRow-module--cds--layout--size-2xl--ca4e2";
export var cdsLayoutSizeLg = "DoDontRow-module--cds--layout--size-lg--8dbc0";
export var cdsLayoutSizeMd = "DoDontRow-module--cds--layout--size-md--067c6";
export var cdsLayoutSizeSm = "DoDontRow-module--cds--layout--size-sm--a3b8b";
export var cdsLayoutSizeXl = "DoDontRow-module--cds--layout--size-xl--571dd";
export var cdsLayoutSizeXs = "DoDontRow-module--cds--layout--size-xs--f040b";
export var cdsShowFeedback = "DoDontRow-module--cds--show-feedback--e3d34";
export var cdsSkeleton = "DoDontRow-module--cds--skeleton--4be8f";
export var cdsVisuallyHidden = "DoDontRow-module--cds--visually-hidden--aaf39";
export var content = "DoDontRow-module--content--65075";
export var correct = "DoDontRow-module--correct--d991a";
export var dark = "DoDontRow-module--dark--04e66";
export var description = "DoDontRow-module--description--edf89";
export var doDontRow = "DoDontRow-module--do-dont-row--5a703";
export var example = "DoDontRow-module--example--c7d71";
export var icon = "DoDontRow-module--icon--d7a7b";
export var iconCorrect = "DoDontRow-module--icon-correct--8fb97";
export var iconIncorrect = "DoDontRow-module--icon-incorrect--3801d";
export var image = "DoDontRow-module--image--de3a5";
export var incorrect = "DoDontRow-module--incorrect--b9bee";
export var ratio = "DoDontRow-module--ratio--2470a";
export var ratio16x9 = "DoDontRow-module--ratio16x9--5af3a";
export var ratio1x1 = "DoDontRow-module--ratio1x1--88113";
export var ratio1x2 = "DoDontRow-module--ratio1x2--3597e";
export var ratio2x1 = "DoDontRow-module--ratio2x1--cf4f5";
export var ratio3x4 = "DoDontRow-module--ratio3x4--c2ec3";
export var ratio4x3 = "DoDontRow-module--ratio4x3--c8ffa";
export var ratio9x16 = "DoDontRow-module--ratio9x16--efd8a";
export var text = "DoDontRow-module--text--40cbd";
export var title = "DoDontRow-module--title--5b09f";