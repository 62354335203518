// extracted by mini-css-extract-plugin
export var addNoScroll = "ImageGallery-module--add-no-scroll--18581";
export var cdsAssistiveText = "ImageGallery-module--cds--assistive-text--ac7e8";
export var cdsHideFeedback = "ImageGallery-module--cds--hide-feedback--098ee";
export var cdsLayoutConstraintDensity__defaultCondensed = "ImageGallery-module--cds--layout-constraint--density__default-condensed--70e7b";
export var cdsLayoutConstraintDensity__defaultNormal = "ImageGallery-module--cds--layout-constraint--density__default-normal--4c467";
export var cdsLayoutConstraintDensity__maxCondensed = "ImageGallery-module--cds--layout-constraint--density__max-condensed--6c39f";
export var cdsLayoutConstraintDensity__maxNormal = "ImageGallery-module--cds--layout-constraint--density__max-normal--d2ef7";
export var cdsLayoutConstraintDensity__minCondensed = "ImageGallery-module--cds--layout-constraint--density__min-condensed--addaa";
export var cdsLayoutConstraintDensity__minNormal = "ImageGallery-module--cds--layout-constraint--density__min-normal--aad72";
export var cdsLayoutConstraintSize__default2xl = "ImageGallery-module--cds--layout-constraint--size__default-2xl--20cc2";
export var cdsLayoutConstraintSize__defaultLg = "ImageGallery-module--cds--layout-constraint--size__default-lg--2e0c2";
export var cdsLayoutConstraintSize__defaultMd = "ImageGallery-module--cds--layout-constraint--size__default-md--d4af0";
export var cdsLayoutConstraintSize__defaultSm = "ImageGallery-module--cds--layout-constraint--size__default-sm--40575";
export var cdsLayoutConstraintSize__defaultXl = "ImageGallery-module--cds--layout-constraint--size__default-xl--ad7dc";
export var cdsLayoutConstraintSize__defaultXs = "ImageGallery-module--cds--layout-constraint--size__default-xs--50d69";
export var cdsLayoutConstraintSize__max2xl = "ImageGallery-module--cds--layout-constraint--size__max-2xl--510a8";
export var cdsLayoutConstraintSize__maxLg = "ImageGallery-module--cds--layout-constraint--size__max-lg--85a4e";
export var cdsLayoutConstraintSize__maxMd = "ImageGallery-module--cds--layout-constraint--size__max-md--2bec4";
export var cdsLayoutConstraintSize__maxSm = "ImageGallery-module--cds--layout-constraint--size__max-sm--9ac6c";
export var cdsLayoutConstraintSize__maxXl = "ImageGallery-module--cds--layout-constraint--size__max-xl--e2ea3";
export var cdsLayoutConstraintSize__maxXs = "ImageGallery-module--cds--layout-constraint--size__max-xs--ec3d2";
export var cdsLayoutConstraintSize__min2xl = "ImageGallery-module--cds--layout-constraint--size__min-2xl--f56a1";
export var cdsLayoutConstraintSize__minLg = "ImageGallery-module--cds--layout-constraint--size__min-lg--17557";
export var cdsLayoutConstraintSize__minMd = "ImageGallery-module--cds--layout-constraint--size__min-md--623e8";
export var cdsLayoutConstraintSize__minSm = "ImageGallery-module--cds--layout-constraint--size__min-sm--3bd99";
export var cdsLayoutConstraintSize__minXl = "ImageGallery-module--cds--layout-constraint--size__min-xl--5c9a2";
export var cdsLayoutConstraintSize__minXs = "ImageGallery-module--cds--layout-constraint--size__min-xs--23264";
export var cdsLayoutDensityCondensed = "ImageGallery-module--cds--layout--density-condensed--64dd7";
export var cdsLayoutDensityNormal = "ImageGallery-module--cds--layout--density-normal--22aed";
export var cdsLayoutSize2xl = "ImageGallery-module--cds--layout--size-2xl--9189a";
export var cdsLayoutSizeLg = "ImageGallery-module--cds--layout--size-lg--b6279";
export var cdsLayoutSizeMd = "ImageGallery-module--cds--layout--size-md--e1a19";
export var cdsLayoutSizeSm = "ImageGallery-module--cds--layout--size-sm--5197a";
export var cdsLayoutSizeXl = "ImageGallery-module--cds--layout--size-xl--b7825";
export var cdsLayoutSizeXs = "ImageGallery-module--cds--layout--size-xs--2c82d";
export var cdsShowFeedback = "ImageGallery-module--cds--show-feedback--5dfa5";
export var cdsSkeleton = "ImageGallery-module--cds--skeleton--e8f63";
export var cdsVisuallyHidden = "ImageGallery-module--cds--visually-hidden--fcd85";
export var closeButton = "ImageGallery-module--close-button--9b0cd";
export var column = "ImageGallery-module--column--c55c7";
export var figure = "ImageGallery-module--figure--44e75";
export var firstRightNav = "ImageGallery-module--first-right-nav--23aee";
export var galleryContainer = "ImageGallery-module--gallery-container--2236c";
export var galleryGrid = "ImageGallery-module--gallery-grid--37bdc";
export var galleryRow = "ImageGallery-module--gallery-row--ba890";
export var icon = "ImageGallery-module--icon--e435c";
export var imageButtonWrapper = "ImageGallery-module--image-button-wrapper--3e920";
export var imageInDialog = "ImageGallery-module--image-in-dialog--75be4";
export var imageTitle = "ImageGallery-module--image-title--fed90";
export var inDialogGalleryContainer = "ImageGallery-module--in-dialog-gallery-container--4fe04";
export var leftNav = "ImageGallery-module--left-nav--db194";
export var navButtons = "ImageGallery-module--nav-buttons--084cb";
export var navButtonsContainer = "ImageGallery-module--nav-buttons-container--00aba";
export var rightNav = "ImageGallery-module--right-nav--416a6";