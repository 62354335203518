// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Title-module--cds--assistive-text--bcba7";
export var cdsHideFeedback = "Title-module--cds--hide-feedback--49b16";
export var cdsLayoutConstraintDensity__defaultCondensed = "Title-module--cds--layout-constraint--density__default-condensed--f5454";
export var cdsLayoutConstraintDensity__defaultNormal = "Title-module--cds--layout-constraint--density__default-normal--2ed9c";
export var cdsLayoutConstraintDensity__maxCondensed = "Title-module--cds--layout-constraint--density__max-condensed--c6024";
export var cdsLayoutConstraintDensity__maxNormal = "Title-module--cds--layout-constraint--density__max-normal--9a3b5";
export var cdsLayoutConstraintDensity__minCondensed = "Title-module--cds--layout-constraint--density__min-condensed--63703";
export var cdsLayoutConstraintDensity__minNormal = "Title-module--cds--layout-constraint--density__min-normal--35ca9";
export var cdsLayoutConstraintSize__default2xl = "Title-module--cds--layout-constraint--size__default-2xl--2242b";
export var cdsLayoutConstraintSize__defaultLg = "Title-module--cds--layout-constraint--size__default-lg--f5807";
export var cdsLayoutConstraintSize__defaultMd = "Title-module--cds--layout-constraint--size__default-md--bb17a";
export var cdsLayoutConstraintSize__defaultSm = "Title-module--cds--layout-constraint--size__default-sm--a0856";
export var cdsLayoutConstraintSize__defaultXl = "Title-module--cds--layout-constraint--size__default-xl--6bfe8";
export var cdsLayoutConstraintSize__defaultXs = "Title-module--cds--layout-constraint--size__default-xs--e03eb";
export var cdsLayoutConstraintSize__max2xl = "Title-module--cds--layout-constraint--size__max-2xl--4b44e";
export var cdsLayoutConstraintSize__maxLg = "Title-module--cds--layout-constraint--size__max-lg--2ca83";
export var cdsLayoutConstraintSize__maxMd = "Title-module--cds--layout-constraint--size__max-md--be8a7";
export var cdsLayoutConstraintSize__maxSm = "Title-module--cds--layout-constraint--size__max-sm--f19c3";
export var cdsLayoutConstraintSize__maxXl = "Title-module--cds--layout-constraint--size__max-xl--f0a74";
export var cdsLayoutConstraintSize__maxXs = "Title-module--cds--layout-constraint--size__max-xs--39ed1";
export var cdsLayoutConstraintSize__min2xl = "Title-module--cds--layout-constraint--size__min-2xl--4be4c";
export var cdsLayoutConstraintSize__minLg = "Title-module--cds--layout-constraint--size__min-lg--82cb6";
export var cdsLayoutConstraintSize__minMd = "Title-module--cds--layout-constraint--size__min-md--7983c";
export var cdsLayoutConstraintSize__minSm = "Title-module--cds--layout-constraint--size__min-sm--0e57f";
export var cdsLayoutConstraintSize__minXl = "Title-module--cds--layout-constraint--size__min-xl--f7382";
export var cdsLayoutConstraintSize__minXs = "Title-module--cds--layout-constraint--size__min-xs--540c0";
export var cdsLayoutDensityCondensed = "Title-module--cds--layout--density-condensed--c60c0";
export var cdsLayoutDensityNormal = "Title-module--cds--layout--density-normal--9be1a";
export var cdsLayoutSize2xl = "Title-module--cds--layout--size-2xl--4a75b";
export var cdsLayoutSizeLg = "Title-module--cds--layout--size-lg--23877";
export var cdsLayoutSizeMd = "Title-module--cds--layout--size-md--6a7ad";
export var cdsLayoutSizeSm = "Title-module--cds--layout--size-sm--861e0";
export var cdsLayoutSizeXl = "Title-module--cds--layout--size-xl--e71bd";
export var cdsLayoutSizeXs = "Title-module--cds--layout--size-xs--a1a42";
export var cdsShowFeedback = "Title-module--cds--show-feedback--c6919";
export var cdsSkeleton = "Title-module--cds--skeleton--9d732";
export var cdsVisuallyHidden = "Title-module--cds--visually-hidden--26707";
export var title = "Title-module--title--c903f";