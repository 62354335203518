// extracted by mini-css-extract-plugin
export var abbr = "Markdown-module--abbr--8ecd6";
export var blockquote = "Markdown-module--blockquote--f963f";
export var cdsAssistiveText = "Markdown-module--cds--assistive-text--298f7";
export var cdsHideFeedback = "Markdown-module--cds--hide-feedback--1a6a3";
export var cdsLayoutConstraintDensity__defaultCondensed = "Markdown-module--cds--layout-constraint--density__default-condensed--8da87";
export var cdsLayoutConstraintDensity__defaultNormal = "Markdown-module--cds--layout-constraint--density__default-normal--df1cb";
export var cdsLayoutConstraintDensity__maxCondensed = "Markdown-module--cds--layout-constraint--density__max-condensed--e1e40";
export var cdsLayoutConstraintDensity__maxNormal = "Markdown-module--cds--layout-constraint--density__max-normal--3e309";
export var cdsLayoutConstraintDensity__minCondensed = "Markdown-module--cds--layout-constraint--density__min-condensed--60423";
export var cdsLayoutConstraintDensity__minNormal = "Markdown-module--cds--layout-constraint--density__min-normal--c1f24";
export var cdsLayoutConstraintSize__default2xl = "Markdown-module--cds--layout-constraint--size__default-2xl--16356";
export var cdsLayoutConstraintSize__defaultLg = "Markdown-module--cds--layout-constraint--size__default-lg--e0d4e";
export var cdsLayoutConstraintSize__defaultMd = "Markdown-module--cds--layout-constraint--size__default-md--eb60d";
export var cdsLayoutConstraintSize__defaultSm = "Markdown-module--cds--layout-constraint--size__default-sm--6891d";
export var cdsLayoutConstraintSize__defaultXl = "Markdown-module--cds--layout-constraint--size__default-xl--3b939";
export var cdsLayoutConstraintSize__defaultXs = "Markdown-module--cds--layout-constraint--size__default-xs--a7290";
export var cdsLayoutConstraintSize__max2xl = "Markdown-module--cds--layout-constraint--size__max-2xl--80c37";
export var cdsLayoutConstraintSize__maxLg = "Markdown-module--cds--layout-constraint--size__max-lg--dd725";
export var cdsLayoutConstraintSize__maxMd = "Markdown-module--cds--layout-constraint--size__max-md--b54d2";
export var cdsLayoutConstraintSize__maxSm = "Markdown-module--cds--layout-constraint--size__max-sm--c2977";
export var cdsLayoutConstraintSize__maxXl = "Markdown-module--cds--layout-constraint--size__max-xl--f7318";
export var cdsLayoutConstraintSize__maxXs = "Markdown-module--cds--layout-constraint--size__max-xs--6a20c";
export var cdsLayoutConstraintSize__min2xl = "Markdown-module--cds--layout-constraint--size__min-2xl--e9609";
export var cdsLayoutConstraintSize__minLg = "Markdown-module--cds--layout-constraint--size__min-lg--6b420";
export var cdsLayoutConstraintSize__minMd = "Markdown-module--cds--layout-constraint--size__min-md--310db";
export var cdsLayoutConstraintSize__minSm = "Markdown-module--cds--layout-constraint--size__min-sm--2a29c";
export var cdsLayoutConstraintSize__minXl = "Markdown-module--cds--layout-constraint--size__min-xl--75483";
export var cdsLayoutConstraintSize__minXs = "Markdown-module--cds--layout-constraint--size__min-xs--216f4";
export var cdsLayoutDensityCondensed = "Markdown-module--cds--layout--density-condensed--6b5ca";
export var cdsLayoutDensityNormal = "Markdown-module--cds--layout--density-normal--fb27a";
export var cdsLayoutSize2xl = "Markdown-module--cds--layout--size-2xl--8c5ce";
export var cdsLayoutSizeLg = "Markdown-module--cds--layout--size-lg--0ebee";
export var cdsLayoutSizeMd = "Markdown-module--cds--layout--size-md--e0d73";
export var cdsLayoutSizeSm = "Markdown-module--cds--layout--size-sm--c8135";
export var cdsLayoutSizeXl = "Markdown-module--cds--layout--size-xl--81419";
export var cdsLayoutSizeXs = "Markdown-module--cds--layout--size-xs--02279";
export var cdsShowFeedback = "Markdown-module--cds--show-feedback--97a9f";
export var cdsSkeleton = "Markdown-module--cds--skeleton--c2011";
export var cdsVisuallyHidden = "Markdown-module--cds--visually-hidden--80119";
export var h1 = "Markdown-module--h1--a3fa2";
export var h2 = "Markdown-module--h2--0539e";
export var h3 = "Markdown-module--h3--277e0";
export var h4 = "Markdown-module--h4--34302";
export var h5 = "Markdown-module--h5--2616c";
export var h6 = "Markdown-module--h6--d7546";
export var ordered = "Markdown-module--ordered--1ff1d";
export var paragraph = "Markdown-module--paragraph--22afb";
export var paragraphResponsive = "Markdown-module--paragraph--responsive--d5d96";
export var sup = "Markdown-module--sup--906a8";
export var unordered = "Markdown-module--unordered--4b7c5";