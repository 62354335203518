// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "GifPlayer-module--cds--assistive-text--9f628";
export var cdsHideFeedback = "GifPlayer-module--cds--hide-feedback--cf5db";
export var cdsLayoutConstraintDensity__defaultCondensed = "GifPlayer-module--cds--layout-constraint--density__default-condensed--59308";
export var cdsLayoutConstraintDensity__defaultNormal = "GifPlayer-module--cds--layout-constraint--density__default-normal--2625f";
export var cdsLayoutConstraintDensity__maxCondensed = "GifPlayer-module--cds--layout-constraint--density__max-condensed--604b8";
export var cdsLayoutConstraintDensity__maxNormal = "GifPlayer-module--cds--layout-constraint--density__max-normal--ec7e9";
export var cdsLayoutConstraintDensity__minCondensed = "GifPlayer-module--cds--layout-constraint--density__min-condensed--fd6fb";
export var cdsLayoutConstraintDensity__minNormal = "GifPlayer-module--cds--layout-constraint--density__min-normal--322c8";
export var cdsLayoutConstraintSize__default2xl = "GifPlayer-module--cds--layout-constraint--size__default-2xl--2940d";
export var cdsLayoutConstraintSize__defaultLg = "GifPlayer-module--cds--layout-constraint--size__default-lg--9922d";
export var cdsLayoutConstraintSize__defaultMd = "GifPlayer-module--cds--layout-constraint--size__default-md--b6c43";
export var cdsLayoutConstraintSize__defaultSm = "GifPlayer-module--cds--layout-constraint--size__default-sm--87a2b";
export var cdsLayoutConstraintSize__defaultXl = "GifPlayer-module--cds--layout-constraint--size__default-xl--ee045";
export var cdsLayoutConstraintSize__defaultXs = "GifPlayer-module--cds--layout-constraint--size__default-xs--e59aa";
export var cdsLayoutConstraintSize__max2xl = "GifPlayer-module--cds--layout-constraint--size__max-2xl--cdf1c";
export var cdsLayoutConstraintSize__maxLg = "GifPlayer-module--cds--layout-constraint--size__max-lg--80b76";
export var cdsLayoutConstraintSize__maxMd = "GifPlayer-module--cds--layout-constraint--size__max-md--1470e";
export var cdsLayoutConstraintSize__maxSm = "GifPlayer-module--cds--layout-constraint--size__max-sm--c34e1";
export var cdsLayoutConstraintSize__maxXl = "GifPlayer-module--cds--layout-constraint--size__max-xl--c5e33";
export var cdsLayoutConstraintSize__maxXs = "GifPlayer-module--cds--layout-constraint--size__max-xs--af9c9";
export var cdsLayoutConstraintSize__min2xl = "GifPlayer-module--cds--layout-constraint--size__min-2xl--def61";
export var cdsLayoutConstraintSize__minLg = "GifPlayer-module--cds--layout-constraint--size__min-lg--f6706";
export var cdsLayoutConstraintSize__minMd = "GifPlayer-module--cds--layout-constraint--size__min-md--1be9d";
export var cdsLayoutConstraintSize__minSm = "GifPlayer-module--cds--layout-constraint--size__min-sm--a329d";
export var cdsLayoutConstraintSize__minXl = "GifPlayer-module--cds--layout-constraint--size__min-xl--20205";
export var cdsLayoutConstraintSize__minXs = "GifPlayer-module--cds--layout-constraint--size__min-xs--3c6d8";
export var cdsLayoutDensityCondensed = "GifPlayer-module--cds--layout--density-condensed--9449f";
export var cdsLayoutDensityNormal = "GifPlayer-module--cds--layout--density-normal--e9b57";
export var cdsLayoutSize2xl = "GifPlayer-module--cds--layout--size-2xl--fb101";
export var cdsLayoutSizeLg = "GifPlayer-module--cds--layout--size-lg--d5244";
export var cdsLayoutSizeMd = "GifPlayer-module--cds--layout--size-md--2eee8";
export var cdsLayoutSizeSm = "GifPlayer-module--cds--layout--size-sm--01b14";
export var cdsLayoutSizeXl = "GifPlayer-module--cds--layout--size-xl--a409c";
export var cdsLayoutSizeXs = "GifPlayer-module--cds--layout--size-xs--a8c59";
export var cdsShowFeedback = "GifPlayer-module--cds--show-feedback--46a91";
export var cdsSkeleton = "GifPlayer-module--cds--skeleton--6a310";
export var cdsVisuallyHidden = "GifPlayer-module--cds--visually-hidden--58bfd";
export var container = "GifPlayer-module--container--cbb6b";
export var controls = "GifPlayer-module--controls--ac003";
export var dark = "GifPlayer-module--dark--6f61f";
export var gifDisplayed = "GifPlayer-module--gif-displayed--8cc4e";
export var gifHidden = "GifPlayer-module--gif-hidden--4177d";
export var gifInDialog = "GifPlayer-module--gif-in-dialog--15780";
export var imgDisplayed = "GifPlayer-module--img-displayed--bd812";
export var imgHidden = "GifPlayer-module--img-hidden--3f785";