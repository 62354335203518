// extracted by mini-css-extract-plugin
export var accordion = "Accordion-module--accordion--90711";
export var cdsAssistiveText = "Accordion-module--cds--assistive-text--6d91d";
export var cdsHideFeedback = "Accordion-module--cds--hide-feedback--37794";
export var cdsLayoutConstraintDensity__defaultCondensed = "Accordion-module--cds--layout-constraint--density__default-condensed--db4e5";
export var cdsLayoutConstraintDensity__defaultNormal = "Accordion-module--cds--layout-constraint--density__default-normal--f4680";
export var cdsLayoutConstraintDensity__maxCondensed = "Accordion-module--cds--layout-constraint--density__max-condensed--2dfa5";
export var cdsLayoutConstraintDensity__maxNormal = "Accordion-module--cds--layout-constraint--density__max-normal--56ca4";
export var cdsLayoutConstraintDensity__minCondensed = "Accordion-module--cds--layout-constraint--density__min-condensed--d8cc1";
export var cdsLayoutConstraintDensity__minNormal = "Accordion-module--cds--layout-constraint--density__min-normal--7ea0e";
export var cdsLayoutConstraintSize__default2xl = "Accordion-module--cds--layout-constraint--size__default-2xl--1d782";
export var cdsLayoutConstraintSize__defaultLg = "Accordion-module--cds--layout-constraint--size__default-lg--5c681";
export var cdsLayoutConstraintSize__defaultMd = "Accordion-module--cds--layout-constraint--size__default-md--a7c19";
export var cdsLayoutConstraintSize__defaultSm = "Accordion-module--cds--layout-constraint--size__default-sm--95925";
export var cdsLayoutConstraintSize__defaultXl = "Accordion-module--cds--layout-constraint--size__default-xl--30548";
export var cdsLayoutConstraintSize__defaultXs = "Accordion-module--cds--layout-constraint--size__default-xs--12d3a";
export var cdsLayoutConstraintSize__max2xl = "Accordion-module--cds--layout-constraint--size__max-2xl--bc418";
export var cdsLayoutConstraintSize__maxLg = "Accordion-module--cds--layout-constraint--size__max-lg--550f9";
export var cdsLayoutConstraintSize__maxMd = "Accordion-module--cds--layout-constraint--size__max-md--1bc7a";
export var cdsLayoutConstraintSize__maxSm = "Accordion-module--cds--layout-constraint--size__max-sm--80727";
export var cdsLayoutConstraintSize__maxXl = "Accordion-module--cds--layout-constraint--size__max-xl--e5b45";
export var cdsLayoutConstraintSize__maxXs = "Accordion-module--cds--layout-constraint--size__max-xs--54c10";
export var cdsLayoutConstraintSize__min2xl = "Accordion-module--cds--layout-constraint--size__min-2xl--eff0e";
export var cdsLayoutConstraintSize__minLg = "Accordion-module--cds--layout-constraint--size__min-lg--98e2f";
export var cdsLayoutConstraintSize__minMd = "Accordion-module--cds--layout-constraint--size__min-md--d1db3";
export var cdsLayoutConstraintSize__minSm = "Accordion-module--cds--layout-constraint--size__min-sm--e7f70";
export var cdsLayoutConstraintSize__minXl = "Accordion-module--cds--layout-constraint--size__min-xl--5a1ae";
export var cdsLayoutConstraintSize__minXs = "Accordion-module--cds--layout-constraint--size__min-xs--a4047";
export var cdsLayoutDensityCondensed = "Accordion-module--cds--layout--density-condensed--2e018";
export var cdsLayoutDensityNormal = "Accordion-module--cds--layout--density-normal--70637";
export var cdsLayoutSize2xl = "Accordion-module--cds--layout--size-2xl--4f69c";
export var cdsLayoutSizeLg = "Accordion-module--cds--layout--size-lg--0f162";
export var cdsLayoutSizeMd = "Accordion-module--cds--layout--size-md--f4b7b";
export var cdsLayoutSizeSm = "Accordion-module--cds--layout--size-sm--cbd79";
export var cdsLayoutSizeXl = "Accordion-module--cds--layout--size-xl--dd08f";
export var cdsLayoutSizeXs = "Accordion-module--cds--layout--size-xs--0fd4b";
export var cdsShowFeedback = "Accordion-module--cds--show-feedback--a8570";
export var cdsSkeleton = "Accordion-module--cds--skeleton--a0824";
export var cdsVisuallyHidden = "Accordion-module--cds--visually-hidden--116fe";