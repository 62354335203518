// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "last-modified-date-module--cds--assistive-text--f7279";
export var cdsHideFeedback = "last-modified-date-module--cds--hide-feedback--d493d";
export var cdsLayoutConstraintDensity__defaultCondensed = "last-modified-date-module--cds--layout-constraint--density__default-condensed--c25aa";
export var cdsLayoutConstraintDensity__defaultNormal = "last-modified-date-module--cds--layout-constraint--density__default-normal--a174c";
export var cdsLayoutConstraintDensity__maxCondensed = "last-modified-date-module--cds--layout-constraint--density__max-condensed--42dad";
export var cdsLayoutConstraintDensity__maxNormal = "last-modified-date-module--cds--layout-constraint--density__max-normal--5d857";
export var cdsLayoutConstraintDensity__minCondensed = "last-modified-date-module--cds--layout-constraint--density__min-condensed--fa9bf";
export var cdsLayoutConstraintDensity__minNormal = "last-modified-date-module--cds--layout-constraint--density__min-normal--16985";
export var cdsLayoutConstraintSize__default2xl = "last-modified-date-module--cds--layout-constraint--size__default-2xl--62cfd";
export var cdsLayoutConstraintSize__defaultLg = "last-modified-date-module--cds--layout-constraint--size__default-lg--521f3";
export var cdsLayoutConstraintSize__defaultMd = "last-modified-date-module--cds--layout-constraint--size__default-md--7ba33";
export var cdsLayoutConstraintSize__defaultSm = "last-modified-date-module--cds--layout-constraint--size__default-sm--c3699";
export var cdsLayoutConstraintSize__defaultXl = "last-modified-date-module--cds--layout-constraint--size__default-xl--6f73b";
export var cdsLayoutConstraintSize__defaultXs = "last-modified-date-module--cds--layout-constraint--size__default-xs--a9a78";
export var cdsLayoutConstraintSize__max2xl = "last-modified-date-module--cds--layout-constraint--size__max-2xl--5883e";
export var cdsLayoutConstraintSize__maxLg = "last-modified-date-module--cds--layout-constraint--size__max-lg--60e3c";
export var cdsLayoutConstraintSize__maxMd = "last-modified-date-module--cds--layout-constraint--size__max-md--316cc";
export var cdsLayoutConstraintSize__maxSm = "last-modified-date-module--cds--layout-constraint--size__max-sm--10d2e";
export var cdsLayoutConstraintSize__maxXl = "last-modified-date-module--cds--layout-constraint--size__max-xl--1f13d";
export var cdsLayoutConstraintSize__maxXs = "last-modified-date-module--cds--layout-constraint--size__max-xs--1192c";
export var cdsLayoutConstraintSize__min2xl = "last-modified-date-module--cds--layout-constraint--size__min-2xl--05b6f";
export var cdsLayoutConstraintSize__minLg = "last-modified-date-module--cds--layout-constraint--size__min-lg--8637c";
export var cdsLayoutConstraintSize__minMd = "last-modified-date-module--cds--layout-constraint--size__min-md--e2450";
export var cdsLayoutConstraintSize__minSm = "last-modified-date-module--cds--layout-constraint--size__min-sm--08b5d";
export var cdsLayoutConstraintSize__minXl = "last-modified-date-module--cds--layout-constraint--size__min-xl--c47f2";
export var cdsLayoutConstraintSize__minXs = "last-modified-date-module--cds--layout-constraint--size__min-xs--7e445";
export var cdsLayoutDensityCondensed = "last-modified-date-module--cds--layout--density-condensed--022f8";
export var cdsLayoutDensityNormal = "last-modified-date-module--cds--layout--density-normal--601c5";
export var cdsLayoutSize2xl = "last-modified-date-module--cds--layout--size-2xl--ae3a2";
export var cdsLayoutSizeLg = "last-modified-date-module--cds--layout--size-lg--99a8c";
export var cdsLayoutSizeMd = "last-modified-date-module--cds--layout--size-md--569b3";
export var cdsLayoutSizeSm = "last-modified-date-module--cds--layout--size-sm--0bc63";
export var cdsLayoutSizeXl = "last-modified-date-module--cds--layout--size-xl--07b46";
export var cdsLayoutSizeXs = "last-modified-date-module--cds--layout--size-xs--3382d";
export var cdsShowFeedback = "last-modified-date-module--cds--show-feedback--e1199";
export var cdsSkeleton = "last-modified-date-module--cds--skeleton--0acd9";
export var cdsVisuallyHidden = "last-modified-date-module--cds--visually-hidden--15ac3";
export var row = "last-modified-date-module--row--f1bec";
export var text = "last-modified-date-module--text--24f26";