// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "NextPrevious-module--cds--assistive-text--e49b0";
export var cdsHideFeedback = "NextPrevious-module--cds--hide-feedback--27822";
export var cdsLayoutConstraintDensity__defaultCondensed = "NextPrevious-module--cds--layout-constraint--density__default-condensed--4701e";
export var cdsLayoutConstraintDensity__defaultNormal = "NextPrevious-module--cds--layout-constraint--density__default-normal--05d04";
export var cdsLayoutConstraintDensity__maxCondensed = "NextPrevious-module--cds--layout-constraint--density__max-condensed--1f8ac";
export var cdsLayoutConstraintDensity__maxNormal = "NextPrevious-module--cds--layout-constraint--density__max-normal--c0996";
export var cdsLayoutConstraintDensity__minCondensed = "NextPrevious-module--cds--layout-constraint--density__min-condensed--e59eb";
export var cdsLayoutConstraintDensity__minNormal = "NextPrevious-module--cds--layout-constraint--density__min-normal--8d91c";
export var cdsLayoutConstraintSize__default2xl = "NextPrevious-module--cds--layout-constraint--size__default-2xl--8c5bd";
export var cdsLayoutConstraintSize__defaultLg = "NextPrevious-module--cds--layout-constraint--size__default-lg--7681d";
export var cdsLayoutConstraintSize__defaultMd = "NextPrevious-module--cds--layout-constraint--size__default-md--d022d";
export var cdsLayoutConstraintSize__defaultSm = "NextPrevious-module--cds--layout-constraint--size__default-sm--3047a";
export var cdsLayoutConstraintSize__defaultXl = "NextPrevious-module--cds--layout-constraint--size__default-xl--3810c";
export var cdsLayoutConstraintSize__defaultXs = "NextPrevious-module--cds--layout-constraint--size__default-xs--f7665";
export var cdsLayoutConstraintSize__max2xl = "NextPrevious-module--cds--layout-constraint--size__max-2xl--ccbe3";
export var cdsLayoutConstraintSize__maxLg = "NextPrevious-module--cds--layout-constraint--size__max-lg--d02f2";
export var cdsLayoutConstraintSize__maxMd = "NextPrevious-module--cds--layout-constraint--size__max-md--45df0";
export var cdsLayoutConstraintSize__maxSm = "NextPrevious-module--cds--layout-constraint--size__max-sm--bcdfb";
export var cdsLayoutConstraintSize__maxXl = "NextPrevious-module--cds--layout-constraint--size__max-xl--d2319";
export var cdsLayoutConstraintSize__maxXs = "NextPrevious-module--cds--layout-constraint--size__max-xs--a670f";
export var cdsLayoutConstraintSize__min2xl = "NextPrevious-module--cds--layout-constraint--size__min-2xl--d3ecb";
export var cdsLayoutConstraintSize__minLg = "NextPrevious-module--cds--layout-constraint--size__min-lg--11ff2";
export var cdsLayoutConstraintSize__minMd = "NextPrevious-module--cds--layout-constraint--size__min-md--3550b";
export var cdsLayoutConstraintSize__minSm = "NextPrevious-module--cds--layout-constraint--size__min-sm--d6f47";
export var cdsLayoutConstraintSize__minXl = "NextPrevious-module--cds--layout-constraint--size__min-xl--149c1";
export var cdsLayoutConstraintSize__minXs = "NextPrevious-module--cds--layout-constraint--size__min-xs--944f3";
export var cdsLayoutDensityCondensed = "NextPrevious-module--cds--layout--density-condensed--252d1";
export var cdsLayoutDensityNormal = "NextPrevious-module--cds--layout--density-normal--4d409";
export var cdsLayoutSize2xl = "NextPrevious-module--cds--layout--size-2xl--7ed2e";
export var cdsLayoutSizeLg = "NextPrevious-module--cds--layout--size-lg--584d3";
export var cdsLayoutSizeMd = "NextPrevious-module--cds--layout--size-md--88d34";
export var cdsLayoutSizeSm = "NextPrevious-module--cds--layout--size-sm--ec649";
export var cdsLayoutSizeXl = "NextPrevious-module--cds--layout--size-xl--00009";
export var cdsLayoutSizeXs = "NextPrevious-module--cds--layout--size-xs--2ed49";
export var cdsShowFeedback = "NextPrevious-module--cds--show-feedback--5afd9";
export var cdsSkeleton = "NextPrevious-module--cds--skeleton--c94c5";
export var cdsVisuallyHidden = "NextPrevious-module--cds--visually-hidden--39740";
export var direction = "NextPrevious-module--direction--026c0";
export var grid = "NextPrevious-module--grid--ff097";
export var isHomepage = "NextPrevious-module--isHomepage--a3f89";
export var link = "NextPrevious-module--link--2902e";
export var linkContainer = "NextPrevious-module--link-container--e6902";
export var name = "NextPrevious-module--name--7350d";
export var wrapper = "NextPrevious-module--wrapper--570e4";