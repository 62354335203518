// extracted by mini-css-extract-plugin
export var active = "GlobalSearch-module--active--fdd1f";
export var cdsAssistiveText = "GlobalSearch-module--cds--assistive-text--bc6c4";
export var cdsHideFeedback = "GlobalSearch-module--cds--hide-feedback--d2208";
export var cdsLayoutConstraintDensity__defaultCondensed = "GlobalSearch-module--cds--layout-constraint--density__default-condensed--9767a";
export var cdsLayoutConstraintDensity__defaultNormal = "GlobalSearch-module--cds--layout-constraint--density__default-normal--f6775";
export var cdsLayoutConstraintDensity__maxCondensed = "GlobalSearch-module--cds--layout-constraint--density__max-condensed--e925a";
export var cdsLayoutConstraintDensity__maxNormal = "GlobalSearch-module--cds--layout-constraint--density__max-normal--81c44";
export var cdsLayoutConstraintDensity__minCondensed = "GlobalSearch-module--cds--layout-constraint--density__min-condensed--00d28";
export var cdsLayoutConstraintDensity__minNormal = "GlobalSearch-module--cds--layout-constraint--density__min-normal--79b27";
export var cdsLayoutConstraintSize__default2xl = "GlobalSearch-module--cds--layout-constraint--size__default-2xl--06d3b";
export var cdsLayoutConstraintSize__defaultLg = "GlobalSearch-module--cds--layout-constraint--size__default-lg--a091f";
export var cdsLayoutConstraintSize__defaultMd = "GlobalSearch-module--cds--layout-constraint--size__default-md--b8998";
export var cdsLayoutConstraintSize__defaultSm = "GlobalSearch-module--cds--layout-constraint--size__default-sm--d041f";
export var cdsLayoutConstraintSize__defaultXl = "GlobalSearch-module--cds--layout-constraint--size__default-xl--ad9f6";
export var cdsLayoutConstraintSize__defaultXs = "GlobalSearch-module--cds--layout-constraint--size__default-xs--48594";
export var cdsLayoutConstraintSize__max2xl = "GlobalSearch-module--cds--layout-constraint--size__max-2xl--c3693";
export var cdsLayoutConstraintSize__maxLg = "GlobalSearch-module--cds--layout-constraint--size__max-lg--71fab";
export var cdsLayoutConstraintSize__maxMd = "GlobalSearch-module--cds--layout-constraint--size__max-md--4e01f";
export var cdsLayoutConstraintSize__maxSm = "GlobalSearch-module--cds--layout-constraint--size__max-sm--424bb";
export var cdsLayoutConstraintSize__maxXl = "GlobalSearch-module--cds--layout-constraint--size__max-xl--bface";
export var cdsLayoutConstraintSize__maxXs = "GlobalSearch-module--cds--layout-constraint--size__max-xs--32947";
export var cdsLayoutConstraintSize__min2xl = "GlobalSearch-module--cds--layout-constraint--size__min-2xl--e3290";
export var cdsLayoutConstraintSize__minLg = "GlobalSearch-module--cds--layout-constraint--size__min-lg--c75ab";
export var cdsLayoutConstraintSize__minMd = "GlobalSearch-module--cds--layout-constraint--size__min-md--9d1e0";
export var cdsLayoutConstraintSize__minSm = "GlobalSearch-module--cds--layout-constraint--size__min-sm--dc044";
export var cdsLayoutConstraintSize__minXl = "GlobalSearch-module--cds--layout-constraint--size__min-xl--628f4";
export var cdsLayoutConstraintSize__minXs = "GlobalSearch-module--cds--layout-constraint--size__min-xs--098b4";
export var cdsLayoutDensityCondensed = "GlobalSearch-module--cds--layout--density-condensed--d630e";
export var cdsLayoutDensityNormal = "GlobalSearch-module--cds--layout--density-normal--f6c57";
export var cdsLayoutSize2xl = "GlobalSearch-module--cds--layout--size-2xl--71b68";
export var cdsLayoutSizeLg = "GlobalSearch-module--cds--layout--size-lg--4e28f";
export var cdsLayoutSizeMd = "GlobalSearch-module--cds--layout--size-md--dc216";
export var cdsLayoutSizeSm = "GlobalSearch-module--cds--layout--size-sm--020ce";
export var cdsLayoutSizeXl = "GlobalSearch-module--cds--layout--size-xl--5be04";
export var cdsLayoutSizeXs = "GlobalSearch-module--cds--layout--size-xs--928f0";
export var cdsShowFeedback = "GlobalSearch-module--cds--show-feedback--cb494";
export var cdsSkeleton = "GlobalSearch-module--cds--skeleton--2aec2";
export var cdsVisuallyHidden = "GlobalSearch-module--cds--visually-hidden--130ca";
export var container = "GlobalSearch-module--container--9eebe";
export var description = "GlobalSearch-module--description--bc44d";
export var hidden = "GlobalSearch-module--hidden--8f0ab";
export var inactive = "GlobalSearch-module--inactive--36364";
export var input = "GlobalSearch-module--input--cd239";
export var inputFocusWithin = "GlobalSearch-module--inputFocusWithin--c19f7";
export var inputWrapper = "GlobalSearch-module--inputWrapper--c27ae";
export var label = "GlobalSearch-module--label--6667c";
export var link = "GlobalSearch-module--link--f89e2";
export var list = "GlobalSearch-module--list--6c566";
export var searchButton = "GlobalSearch-module--searchButton--78204";
export var searchButtonClose = "GlobalSearch-module--search-button--close--561d0";
export var tab = "GlobalSearch-module--tab--20d31";