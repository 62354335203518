// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "LeftNavWrapper-module--cds--assistive-text--0f63f";
export var cdsHideFeedback = "LeftNavWrapper-module--cds--hide-feedback--d7839";
export var cdsLayoutConstraintDensity__defaultCondensed = "LeftNavWrapper-module--cds--layout-constraint--density__default-condensed--54472";
export var cdsLayoutConstraintDensity__defaultNormal = "LeftNavWrapper-module--cds--layout-constraint--density__default-normal--66bfb";
export var cdsLayoutConstraintDensity__maxCondensed = "LeftNavWrapper-module--cds--layout-constraint--density__max-condensed--bae0b";
export var cdsLayoutConstraintDensity__maxNormal = "LeftNavWrapper-module--cds--layout-constraint--density__max-normal--b4ac4";
export var cdsLayoutConstraintDensity__minCondensed = "LeftNavWrapper-module--cds--layout-constraint--density__min-condensed--fb778";
export var cdsLayoutConstraintDensity__minNormal = "LeftNavWrapper-module--cds--layout-constraint--density__min-normal--aa40b";
export var cdsLayoutConstraintSize__default2xl = "LeftNavWrapper-module--cds--layout-constraint--size__default-2xl--97d1b";
export var cdsLayoutConstraintSize__defaultLg = "LeftNavWrapper-module--cds--layout-constraint--size__default-lg--644f2";
export var cdsLayoutConstraintSize__defaultMd = "LeftNavWrapper-module--cds--layout-constraint--size__default-md--37ba7";
export var cdsLayoutConstraintSize__defaultSm = "LeftNavWrapper-module--cds--layout-constraint--size__default-sm--8d565";
export var cdsLayoutConstraintSize__defaultXl = "LeftNavWrapper-module--cds--layout-constraint--size__default-xl--61955";
export var cdsLayoutConstraintSize__defaultXs = "LeftNavWrapper-module--cds--layout-constraint--size__default-xs--452f3";
export var cdsLayoutConstraintSize__max2xl = "LeftNavWrapper-module--cds--layout-constraint--size__max-2xl--1507c";
export var cdsLayoutConstraintSize__maxLg = "LeftNavWrapper-module--cds--layout-constraint--size__max-lg--415c3";
export var cdsLayoutConstraintSize__maxMd = "LeftNavWrapper-module--cds--layout-constraint--size__max-md--5cc55";
export var cdsLayoutConstraintSize__maxSm = "LeftNavWrapper-module--cds--layout-constraint--size__max-sm--fc8ff";
export var cdsLayoutConstraintSize__maxXl = "LeftNavWrapper-module--cds--layout-constraint--size__max-xl--db022";
export var cdsLayoutConstraintSize__maxXs = "LeftNavWrapper-module--cds--layout-constraint--size__max-xs--bf4ed";
export var cdsLayoutConstraintSize__min2xl = "LeftNavWrapper-module--cds--layout-constraint--size__min-2xl--7d542";
export var cdsLayoutConstraintSize__minLg = "LeftNavWrapper-module--cds--layout-constraint--size__min-lg--24e31";
export var cdsLayoutConstraintSize__minMd = "LeftNavWrapper-module--cds--layout-constraint--size__min-md--c3e3d";
export var cdsLayoutConstraintSize__minSm = "LeftNavWrapper-module--cds--layout-constraint--size__min-sm--ca2d3";
export var cdsLayoutConstraintSize__minXl = "LeftNavWrapper-module--cds--layout-constraint--size__min-xl--fd03b";
export var cdsLayoutConstraintSize__minXs = "LeftNavWrapper-module--cds--layout-constraint--size__min-xs--bbcc0";
export var cdsLayoutDensityCondensed = "LeftNavWrapper-module--cds--layout--density-condensed--bde95";
export var cdsLayoutDensityNormal = "LeftNavWrapper-module--cds--layout--density-normal--4238e";
export var cdsLayoutSize2xl = "LeftNavWrapper-module--cds--layout--size-2xl--95ded";
export var cdsLayoutSizeLg = "LeftNavWrapper-module--cds--layout--size-lg--aa17f";
export var cdsLayoutSizeMd = "LeftNavWrapper-module--cds--layout--size-md--b5f63";
export var cdsLayoutSizeSm = "LeftNavWrapper-module--cds--layout--size-sm--89874";
export var cdsLayoutSizeXl = "LeftNavWrapper-module--cds--layout--size-xl--a2184";
export var cdsLayoutSizeXs = "LeftNavWrapper-module--cds--layout--size-xs--f20ea";
export var cdsShowFeedback = "LeftNavWrapper-module--cds--show-feedback--f706a";
export var cdsSkeleton = "LeftNavWrapper-module--cds--skeleton--d4462";
export var cdsVisuallyHidden = "LeftNavWrapper-module--cds--visually-hidden--70c7a";
export var expanded = "LeftNavWrapper-module--expanded--e2d5f";
export var leftNavWrapper = "LeftNavWrapper-module--leftNavWrapper--20f7e";
export var useHeaderNavStyles = "LeftNavWrapper-module--useHeaderNavStyles--7962e";