// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "LeftNav-module--cds--assistive-text--d5c56";
export var cdsHideFeedback = "LeftNav-module--cds--hide-feedback--c1318";
export var cdsLayoutConstraintDensity__defaultCondensed = "LeftNav-module--cds--layout-constraint--density__default-condensed--a234b";
export var cdsLayoutConstraintDensity__defaultNormal = "LeftNav-module--cds--layout-constraint--density__default-normal--e9e96";
export var cdsLayoutConstraintDensity__maxCondensed = "LeftNav-module--cds--layout-constraint--density__max-condensed--60899";
export var cdsLayoutConstraintDensity__maxNormal = "LeftNav-module--cds--layout-constraint--density__max-normal--250e0";
export var cdsLayoutConstraintDensity__minCondensed = "LeftNav-module--cds--layout-constraint--density__min-condensed--5079f";
export var cdsLayoutConstraintDensity__minNormal = "LeftNav-module--cds--layout-constraint--density__min-normal--045da";
export var cdsLayoutConstraintSize__default2xl = "LeftNav-module--cds--layout-constraint--size__default-2xl--77d1c";
export var cdsLayoutConstraintSize__defaultLg = "LeftNav-module--cds--layout-constraint--size__default-lg--989ca";
export var cdsLayoutConstraintSize__defaultMd = "LeftNav-module--cds--layout-constraint--size__default-md--a4674";
export var cdsLayoutConstraintSize__defaultSm = "LeftNav-module--cds--layout-constraint--size__default-sm--716f7";
export var cdsLayoutConstraintSize__defaultXl = "LeftNav-module--cds--layout-constraint--size__default-xl--ec5e9";
export var cdsLayoutConstraintSize__defaultXs = "LeftNav-module--cds--layout-constraint--size__default-xs--9ba29";
export var cdsLayoutConstraintSize__max2xl = "LeftNav-module--cds--layout-constraint--size__max-2xl--b64a5";
export var cdsLayoutConstraintSize__maxLg = "LeftNav-module--cds--layout-constraint--size__max-lg--f6f14";
export var cdsLayoutConstraintSize__maxMd = "LeftNav-module--cds--layout-constraint--size__max-md--ad70e";
export var cdsLayoutConstraintSize__maxSm = "LeftNav-module--cds--layout-constraint--size__max-sm--bc9d1";
export var cdsLayoutConstraintSize__maxXl = "LeftNav-module--cds--layout-constraint--size__max-xl--701aa";
export var cdsLayoutConstraintSize__maxXs = "LeftNav-module--cds--layout-constraint--size__max-xs--4a4f1";
export var cdsLayoutConstraintSize__min2xl = "LeftNav-module--cds--layout-constraint--size__min-2xl--24ece";
export var cdsLayoutConstraintSize__minLg = "LeftNav-module--cds--layout-constraint--size__min-lg--422b4";
export var cdsLayoutConstraintSize__minMd = "LeftNav-module--cds--layout-constraint--size__min-md--d10c8";
export var cdsLayoutConstraintSize__minSm = "LeftNav-module--cds--layout-constraint--size__min-sm--6971a";
export var cdsLayoutConstraintSize__minXl = "LeftNav-module--cds--layout-constraint--size__min-xl--01fed";
export var cdsLayoutConstraintSize__minXs = "LeftNav-module--cds--layout-constraint--size__min-xs--2e27b";
export var cdsLayoutDensityCondensed = "LeftNav-module--cds--layout--density-condensed--3ceaf";
export var cdsLayoutDensityNormal = "LeftNav-module--cds--layout--density-normal--07325";
export var cdsLayoutSize2xl = "LeftNav-module--cds--layout--size-2xl--1f10f";
export var cdsLayoutSizeLg = "LeftNav-module--cds--layout--size-lg--71673";
export var cdsLayoutSizeMd = "LeftNav-module--cds--layout--size-md--6e49d";
export var cdsLayoutSizeSm = "LeftNav-module--cds--layout--size-sm--da58a";
export var cdsLayoutSizeXl = "LeftNav-module--cds--layout--size-xl--2931c";
export var cdsLayoutSizeXs = "LeftNav-module--cds--layout--size-xs--60a12";
export var cdsShowFeedback = "LeftNav-module--cds--show-feedback--7762a";
export var cdsSkeleton = "LeftNav-module--cds--skeleton--366b5";
export var cdsVisuallyHidden = "LeftNav-module--cds--visually-hidden--8258f";
export var currentItem = "LeftNav-module--current-item--9ae72";
export var divider = "LeftNav-module--divider--e308b";
export var dividerSpace = "LeftNav-module--divider-space--81b7a";
export var outboundLink = "LeftNav-module--outboundLink--4686f";
export var sideNavDark = "LeftNav-module--side-nav--dark--2ffde";
export var sideNavWhite = "LeftNav-module--side-nav--white--b3803";