// extracted by mini-css-extract-plugin
export var caption = "Caption-module--caption--e64b3";
export var captionResponsive = "Caption-module--caption-responsive--d7256";
export var cdsAssistiveText = "Caption-module--cds--assistive-text--e6f80";
export var cdsHideFeedback = "Caption-module--cds--hide-feedback--210b3";
export var cdsLayoutConstraintDensity__defaultCondensed = "Caption-module--cds--layout-constraint--density__default-condensed--640b3";
export var cdsLayoutConstraintDensity__defaultNormal = "Caption-module--cds--layout-constraint--density__default-normal--52e47";
export var cdsLayoutConstraintDensity__maxCondensed = "Caption-module--cds--layout-constraint--density__max-condensed--34b43";
export var cdsLayoutConstraintDensity__maxNormal = "Caption-module--cds--layout-constraint--density__max-normal--3baaa";
export var cdsLayoutConstraintDensity__minCondensed = "Caption-module--cds--layout-constraint--density__min-condensed--9c490";
export var cdsLayoutConstraintDensity__minNormal = "Caption-module--cds--layout-constraint--density__min-normal--6b05b";
export var cdsLayoutConstraintSize__default2xl = "Caption-module--cds--layout-constraint--size__default-2xl--a353a";
export var cdsLayoutConstraintSize__defaultLg = "Caption-module--cds--layout-constraint--size__default-lg--d7f0b";
export var cdsLayoutConstraintSize__defaultMd = "Caption-module--cds--layout-constraint--size__default-md--5b5dc";
export var cdsLayoutConstraintSize__defaultSm = "Caption-module--cds--layout-constraint--size__default-sm--0d56e";
export var cdsLayoutConstraintSize__defaultXl = "Caption-module--cds--layout-constraint--size__default-xl--e73b3";
export var cdsLayoutConstraintSize__defaultXs = "Caption-module--cds--layout-constraint--size__default-xs--267b1";
export var cdsLayoutConstraintSize__max2xl = "Caption-module--cds--layout-constraint--size__max-2xl--fd477";
export var cdsLayoutConstraintSize__maxLg = "Caption-module--cds--layout-constraint--size__max-lg--df311";
export var cdsLayoutConstraintSize__maxMd = "Caption-module--cds--layout-constraint--size__max-md--56400";
export var cdsLayoutConstraintSize__maxSm = "Caption-module--cds--layout-constraint--size__max-sm--4e0aa";
export var cdsLayoutConstraintSize__maxXl = "Caption-module--cds--layout-constraint--size__max-xl--0418f";
export var cdsLayoutConstraintSize__maxXs = "Caption-module--cds--layout-constraint--size__max-xs--93541";
export var cdsLayoutConstraintSize__min2xl = "Caption-module--cds--layout-constraint--size__min-2xl--46627";
export var cdsLayoutConstraintSize__minLg = "Caption-module--cds--layout-constraint--size__min-lg--93227";
export var cdsLayoutConstraintSize__minMd = "Caption-module--cds--layout-constraint--size__min-md--9b096";
export var cdsLayoutConstraintSize__minSm = "Caption-module--cds--layout-constraint--size__min-sm--ac6bd";
export var cdsLayoutConstraintSize__minXl = "Caption-module--cds--layout-constraint--size__min-xl--e410b";
export var cdsLayoutConstraintSize__minXs = "Caption-module--cds--layout-constraint--size__min-xs--65e6b";
export var cdsLayoutDensityCondensed = "Caption-module--cds--layout--density-condensed--31822";
export var cdsLayoutDensityNormal = "Caption-module--cds--layout--density-normal--fc30e";
export var cdsLayoutSize2xl = "Caption-module--cds--layout--size-2xl--04303";
export var cdsLayoutSizeLg = "Caption-module--cds--layout--size-lg--11ac6";
export var cdsLayoutSizeMd = "Caption-module--cds--layout--size-md--50c3f";
export var cdsLayoutSizeSm = "Caption-module--cds--layout--size-sm--21073";
export var cdsLayoutSizeXl = "Caption-module--cds--layout--size-xl--cce5a";
export var cdsLayoutSizeXs = "Caption-module--cds--layout--size-xs--32d3b";
export var cdsShowFeedback = "Caption-module--cds--show-feedback--66a76";
export var cdsSkeleton = "Caption-module--cds--skeleton--a92a4";
export var cdsVisuallyHidden = "Caption-module--cds--visually-hidden--99abf";