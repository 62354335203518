// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "InlineNotification-module--cds--assistive-text--ca47f";
export var cdsHideFeedback = "InlineNotification-module--cds--hide-feedback--be29c";
export var cdsLayoutConstraintDensity__defaultCondensed = "InlineNotification-module--cds--layout-constraint--density__default-condensed--c03a6";
export var cdsLayoutConstraintDensity__defaultNormal = "InlineNotification-module--cds--layout-constraint--density__default-normal--37e63";
export var cdsLayoutConstraintDensity__maxCondensed = "InlineNotification-module--cds--layout-constraint--density__max-condensed--520ee";
export var cdsLayoutConstraintDensity__maxNormal = "InlineNotification-module--cds--layout-constraint--density__max-normal--87173";
export var cdsLayoutConstraintDensity__minCondensed = "InlineNotification-module--cds--layout-constraint--density__min-condensed--5630e";
export var cdsLayoutConstraintDensity__minNormal = "InlineNotification-module--cds--layout-constraint--density__min-normal--0ed34";
export var cdsLayoutConstraintSize__default2xl = "InlineNotification-module--cds--layout-constraint--size__default-2xl--0402d";
export var cdsLayoutConstraintSize__defaultLg = "InlineNotification-module--cds--layout-constraint--size__default-lg--7ebfd";
export var cdsLayoutConstraintSize__defaultMd = "InlineNotification-module--cds--layout-constraint--size__default-md--4ca1a";
export var cdsLayoutConstraintSize__defaultSm = "InlineNotification-module--cds--layout-constraint--size__default-sm--38f44";
export var cdsLayoutConstraintSize__defaultXl = "InlineNotification-module--cds--layout-constraint--size__default-xl--0bd12";
export var cdsLayoutConstraintSize__defaultXs = "InlineNotification-module--cds--layout-constraint--size__default-xs--65e03";
export var cdsLayoutConstraintSize__max2xl = "InlineNotification-module--cds--layout-constraint--size__max-2xl--42650";
export var cdsLayoutConstraintSize__maxLg = "InlineNotification-module--cds--layout-constraint--size__max-lg--20fe6";
export var cdsLayoutConstraintSize__maxMd = "InlineNotification-module--cds--layout-constraint--size__max-md--b7560";
export var cdsLayoutConstraintSize__maxSm = "InlineNotification-module--cds--layout-constraint--size__max-sm--35e33";
export var cdsLayoutConstraintSize__maxXl = "InlineNotification-module--cds--layout-constraint--size__max-xl--2eeff";
export var cdsLayoutConstraintSize__maxXs = "InlineNotification-module--cds--layout-constraint--size__max-xs--eb8b4";
export var cdsLayoutConstraintSize__min2xl = "InlineNotification-module--cds--layout-constraint--size__min-2xl--611f9";
export var cdsLayoutConstraintSize__minLg = "InlineNotification-module--cds--layout-constraint--size__min-lg--1bb3d";
export var cdsLayoutConstraintSize__minMd = "InlineNotification-module--cds--layout-constraint--size__min-md--8e5be";
export var cdsLayoutConstraintSize__minSm = "InlineNotification-module--cds--layout-constraint--size__min-sm--d159a";
export var cdsLayoutConstraintSize__minXl = "InlineNotification-module--cds--layout-constraint--size__min-xl--a5550";
export var cdsLayoutConstraintSize__minXs = "InlineNotification-module--cds--layout-constraint--size__min-xs--57f4e";
export var cdsLayoutDensityCondensed = "InlineNotification-module--cds--layout--density-condensed--b5694";
export var cdsLayoutDensityNormal = "InlineNotification-module--cds--layout--density-normal--1dd5c";
export var cdsLayoutSize2xl = "InlineNotification-module--cds--layout--size-2xl--d4e6d";
export var cdsLayoutSizeLg = "InlineNotification-module--cds--layout--size-lg--5a56d";
export var cdsLayoutSizeMd = "InlineNotification-module--cds--layout--size-md--d80e6";
export var cdsLayoutSizeSm = "InlineNotification-module--cds--layout--size-sm--2ba08";
export var cdsLayoutSizeXl = "InlineNotification-module--cds--layout--size-xl--28b5a";
export var cdsLayoutSizeXs = "InlineNotification-module--cds--layout--size-xs--328f8";
export var cdsShowFeedback = "InlineNotification-module--cds--show-feedback--9dbc9";
export var cdsSkeleton = "InlineNotification-module--cds--skeleton--646ef";
export var cdsVisuallyHidden = "InlineNotification-module--cds--visually-hidden--6aceb";
export var darkMode = "InlineNotification-module--darkMode--6f455";
export var notification = "InlineNotification-module--notification--1ce35";